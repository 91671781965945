

.blog_section .heading_container {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}




.blog_section .heading_container h2::before {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.blog_section .box {
  margin-top: 55px;
  /*background-color: red;/*f1f2f3*/
}

.blog_section .box .img-box {
  position: relative;
}

.blog_section .box .img-box .blog_date {
  position: absolute;
  left: 25px;
  bottom: -15px;
  padding: 10px 15px;
  font-size: 14px;
  background-color: #718096;
  margin: 0;
  text-align: center;
  border: 3px solid #EDF2F7; /*ffffff*/
  color: #ffffff;
}

.blog_section .box .img-box img {
  width: 100%;
}

.blog_section .box .detail-box {
  margin-top: 10px;
  padding: 25px;
}

.blog_section .box .detail-box h5 {
  font-weight: bold;
}

.blog_section .box .detail-box p {
  font-size: 15px;
}

.blog_section .box .detail-box a {
  display: inline-block;
  padding: 10px 30px;
  background-color: transparent;
  /*color: #160e0b;*/
  border-radius: 0;
  border: 1px solid #805AD5;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.blog_section .box .detail-box a:hover {
  background-color: #805AD5;
  color: #ffffff;
}

